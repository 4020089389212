import React from 'react'
import MaterialTable from 'material-table'
import '../../../../styles/scss/components/equote-nested-cabins-table.scss'
import renderCabinTableColumns from '../../../../utilities/renderCabinTableColumns'
import CloseImage from '../../../../assets/icons/close.svg'

const headerStyle = {
  maxWidth: '177px',
  whiteSpace: 'nowrap'
}

const EQuoteSailingsFullSearchCabinTable = props => {
  const {
    superCategoryFullName,
    cabins,
    type,
    closeHandler,
    currencySymbol,
    currencySymbolPlacement,
    packageData
  } = props

  const sortedCabins = Object.entries(cabins)
    .sort((a, b) => {
      if (a[0] === 'pricingHeader') {
        return -1
      }
      return a[1].bestGuestCountRate < b[1].bestGuestCountRate ? -1 : 1
    })
    .map(cabin => cabin[1])

  function renderMultipleGuestCountPricingTables() {
    const initialColumns = [
      {
        title: 'Category',
        field: 'category',
        isPricing: false,
        order: 1,
        cellStyle: {
          padding: '30px 20px 30px 20px',
          fontSize: 15
        },
        headerStyle: {
          padding: 10
        }
      },
      {
        title: 'Category Name',
        field: 'categoryName',
        isPricing: false,
        order: 2,
        cellStyle: {
          padding: '30px 20px 30px 20px',
          fontSize: 15
        },
        headerStyle: {
          padding: 10
        }
      }
    ]
    const columns = renderCabinTableColumns({
      columns: initialColumns,
      cabins,
      headerStyle,
      currencySymbol,
      packageData,
      currencySymbolPlacement
    })

    return (
      <MaterialTable
        components={{
          Container: tableProps => {
            return (
              <div className="u-table sub-table equote__full-search-cabins-table u-nested-cabins-table-container">
                {tableProps.children}
              </div>
            )
          }
        }}
        columns={columns}
        data={sortedCabins}
        options={{
          paging: false,
          showEmptyDataSourceMessage: true,
          toolbar: false,
          sorting: false
        }}
        localization={{
          body: {
            emptyDataSourceMessage:
              'No cabins available, please try another date range.'
          }
        }}
      />
    )
  }
  return (
    <div
      key={cabins.pricingDisplayName}
      data-type={type}
      className="equote__sailings-full-search-cabins-table"
    >
      <div className="equote__sailings-full-search-cabins-table-heading">
        <h5>{superCategoryFullName || type} Categories</h5>
        {closeHandler && (
          <div
            className="u-modal__close"
            onClick={closeHandler}
            onKeyPress={closeHandler}
            tabIndex={0}
            role="button"
          >
            CLOSE
            <img
              className="u-modal__close-icon--large"
              src={CloseImage}
              alt="Close Table"
            />
          </div>
        )}
      </div>
      {renderMultipleGuestCountPricingTables()}
    </div>
  )
}

export default EQuoteSailingsFullSearchCabinTable
