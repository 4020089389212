import React from 'react'
import { get } from 'lodash'
import formatPrice from '../../utilities/formatPrice'
import brands from '../../configuration/brands'

import VirtualTourArrowButton from '../elements/VirtualTourArrowButton'
import TaxLine from '../TaxLine'
import Price from '../Price'

function renderStateRoomTitle(stateRoom, roomData, isSuperCategory, brand) {
  let title = roomData.name
    ? roomData.name
    : stateRoom.stateRoomDescription
    ? stateRoom.stateRoomDescription
    : brands[brand] &&
      brands[brand].stateRooms &&
      brands[brand].stateRooms[stateRoom.stateroomType] &&
      brands[brand].stateRooms[stateRoom.stateroomType].title
    ? brands[brand].stateRooms[stateRoom.stateroomType].title
    : ''

  if (isSuperCategory) {
    return title
  } else {
    return stateRoom.stateroomCategoryCode
      ? `${title} ${stateRoom.stateroomCategoryCode}`
      : title
  }
}

function renderImageSrc(images, brand, superCategoryCode) {
  if (images && images[0] && images[0].sizes) {
    if (images[0].sizes[`stateroom_${brand.toLowerCase()}`]) {
      return images[0].sizes[`stateroom_${brand.toLowerCase()}`]
    }
    if (images[0].sizes.staterooms && images[0].sizes.staterooms) {
      return images[0].sizes.staterooms
    }
  }

  if (images && images[0] && images[0].file) {
    return images[0].file
  }

  return brands[brand].stateRoomImages[superCategoryCode]
}

const EQuotePackageDetailsStateRoom = ({
  stateRoom,
  brand,
  imgHeight,
  activeSearchFilterData,
  isCarouselView,
  currency,
  currencySymbol,
  currencySymbolPlacement,
  openVirtualTour,
  brandClassName,
  handleImageClick
}) => {
  const roomData = stateRoom.isSuperCategory
    ? stateRoom.category
    : stateRoom.stateroom

  const tourText = get(roomData, 'virtual_tour_button_text', null)

  const virtualTourText = tourText && tourText !== 0 ? tourText : 'Virtual Tour'

  const virtualTourLink = get(roomData, 'virtual_tour_button_link', null)

  const stateRoomImage = renderImageSrc(
    roomData.image,
    brand,
    stateRoom.superCategoryCode
  )
  const imgWidth = (imgHeight / stateRoomImage.height) * stateRoomImage.width

  const { promos, isSuperCategory, isRefundable } = stateRoom

  const discountLabels = renderDisclaimerList(activeSearchFilterData, promos)

  const title = renderStateRoomTitle(
    stateRoom,
    roomData,
    isSuperCategory,
    brand
  )

  const taxes = get(stateRoom, 'priceInfo.taxesFees')

  return (
    <div className="equote__package-details-state-room-wrapper">
      <div className="equote__package-details-state-room">
        <div className="equote__package-details-state-room--header stateroom-carousel-only">
          <div className="equote__package-details-state-room--header--title">
            <p className="u-remove-margin">{title}</p>
            {virtualTourLink && virtualTourLink.length > 0 ? (
              <VirtualTourArrowButton
                onClick={() =>
                  openVirtualTour({
                    link: virtualTourLink,
                    title: virtualTourText
                  })
                }
                text={virtualTourText}
                brandClassName={brandClassName}
              />
            ) : null}
          </div>
        </div>
        <div
          className="equote__package-details-state-room--image-container"
          style={{
            width: !isCarouselView
              ? imgWidth > 1
                ? imgWidth
                : imgHeight
              : '100%',
            height: !isCarouselView ? imgHeight : 'auto'
          }}
          role="button"
          onClick={() => handleImageClick(stateRoomImage.src)}
        >
          <img
            alt={roomData.name}
            src={stateRoomImage.src}
            className="equote__package-details-state-room--image-container-picture"
          />
        </div>
        <div className="equote__package-details-state-room--body">
          <div className="equote__package-details-state-room--body--section">
            <div className="equote__package-details-state-room--header stateroom-desktop-only">
              <div className="equote__package-details-state-room--header--title">
                <p className="u-remove-margin">{title}</p>
              </div>
              {virtualTourLink && virtualTourLink.length > 0 ? (
                <div
                  className={`details-state-room--image-container-virtual-tour-trigger ${brandClassName}`}
                >
                  <VirtualTourArrowButton
                    onClick={() =>
                      openVirtualTour({
                        link: virtualTourLink,
                        title: virtualTourText
                      })
                    }
                    text={virtualTourText}
                    brandClassName={brandClassName}
                  />
                </div>
              ) : null}
            </div>
            <div className="equote__package-details-state-room--body--section-details">
              <div className="equote__package-details-state-room--pricing">
                <table className="equote__package-details-state-room--pricing--prices">
                  <thead>
                    <tr>
                      <td>Price from...</td>
                      <td>
                        {!isRefundable && (
                          <span className="equote__package-details-state-room--pricing--header-non-refundable-flag">
                            *NON-REFUNDABLE DEPOSIT
                          </span>
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {stateRoom.pricing && stateRoom.pricing.length
                      ? stateRoom.pricing.map((price, i) => (
                          <tr
                            key={price.displayName + i}
                            className="equote__package-details-state-room--pricing--prices-price"
                          >
                            <td className="equote__package-details-state-room--pricing--prices-price--name">
                              {price.displayName}
                            </td>
                            <td className="equote__package-details-state-room--pricing--prices-price--amount">
                              <strong>
                                <Price
                                  price={formatPrice(price.displayPrice)}
                                  currencySymbol={currencySymbol}
                                  currencySymbolPlacement={
                                    currencySymbolPlacement
                                  }
                                />{' '}
                                {currency}
                              </strong>
                              <span className="equote__package-details-state-room--pricing--prices-price--promo">
                                {price.displayLeadPromoDesc
                                  ? price.displayLeadPromoDesc
                                  : null}{' '}
                                {price.displayCreditAmt ? (
                                  <>
                                    <Price
                                      price={`${
                                        price.displayCreditAmt
                                          ? price.displayCreditAmt.toFixed(2)
                                          : ''
                                      }`}
                                      currencySymbol={currencySymbol}
                                      currencySymbolPlacement={
                                        currencySymbolPlacement
                                      }
                                    />{' '}
                                    OBC
                                  </>
                                ) : null}
                              </span>
                            </td>
                          </tr>
                        ))
                      : '-'}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!discountLabels.length && (
        <div className="state-room-disclaimers">
          {discountLabels.map(discount => (
            <p>{discount}</p>
          ))}
        </div>
      )}
      {taxes ? (
        <p className="state-room-taxes">
          <i>
            <TaxLine
              currency={currency}
              currencySymbol={currencySymbol}
              currencySymbolPlacement={currencySymbolPlacement}
              amount={taxes}
            />
          </i>
        </p>
      ) : null}
    </div>
  )
}

export default EQuotePackageDetailsStateRoom

const shouldShowDisclaimer = (promoList, disclaimersToCheck) => {
  return promoList.some(promo => {
    return disclaimersToCheck.some(
      disclaimerCode => disclaimerCode === promo.code
    )
  })
}

const renderDisclaimerList = (activeSearchFilterData, promoList) => {
  if (!promoList) return []
  const discountLabels = []
  if (
    activeSearchFilterData &&
    activeSearchFilterData.isSeniorCitizen &&
    shouldShowDisclaimer(promoList, ['SR', 'S2', 'SB'])
  ) {
    discountLabels.push(
      '**One guest in the stateroom must be 55 years of age or older.'
    )
  }

  if (
    activeSearchFilterData &&
    activeSearchFilterData.isServiceMember &&
    shouldShowDisclaimer(promoList, ['MI', 'OS', 'M1'])
  ) {
    discountLabels.push(
      '**One guest in the stateroom must be an active personnel currently servicing in the Canadian National Defense or United States Military, a retiree of these services, a veteran with an honorable discharge, or a spouse of an actively deployed military personnel.'
    )
  }
  if (
    activeSearchFilterData &&
    (activeSearchFilterData.isPolice || activeSearchFilterData.isFireFighter) &&
    shouldShowDisclaimer(promoList, ['PO', 'PL', 'FI', 'F1'])
  ) {
    discountLabels.push(
      '**One guest in the stateroom must be an active, retired or volunteer employee of a city or state/province Law Enforcement Agency, Fire Department or Emergency Medical Services in the United States or Canada.'
    )
  }
  if (
    activeSearchFilterData &&
    activeSearchFilterData.residentOf &&
    shouldShowDisclaimer(promoList, ['RE', 'IS', 'RS', 'R2'])
  ) {
    discountLabels.push(
      '**Resident Special – One guest in the stateroom must be a resident of an eligible state to qualify for the offer.'
    )
  }

  if (
    activeSearchFilterData &&
    activeSearchFilterData.loyaltyId &&
    shouldShowDisclaimer(promoList, [
      'WO',
      'CE',
      'CL',
      'CT',
      'LB',
      'LG',
      'LO',
      'OC',
      'ON',
      'C1',
      'CB',
      'CD',
      'EV',
      'LX',
      'OL'
    ])
  ) {
    discountLabels.push(
      '**Loyalty Rate – One guest in the stateroom must be an eligible member of our loyalty program. "'
    )
  }

  return discountLabels
}
