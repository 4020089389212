import { get } from 'lodash'
import {
  FETCH_CONFIG_BEGIN,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
  SET_CURRENT_QUERY_PARAMS,
  SET_REFERER
} from '../actions/fetchConfiguration'
import { ENABLE_MAINTENANCE_MODE } from '../actions/fetchStaticData'
import { getLabelsData } from '../sessionStorage/getLabelsData'
import { getIsBundlePricing } from './fetchSsoReducers'
import { formatTerms, formatCode } from '../utilities/formatTerms'

const initialState = {
  configuration: [],
  loading: false,
  error: null,
  maintenanceMode: false,
  referrer: null,
  referrerData: null
}

export default function configurationReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_QUERY_PARAMS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        currentQueryParams: action.payload
      }
    case FETCH_CONFIG_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_CONFIG_SUCCESS:
      // Request success: set loading false, set config data
      return {
        ...state,
        loading: false,
        configuration: action.payload
      }

    case FETCH_CONFIG_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        configuration: []
      }
    case ENABLE_MAINTENANCE_MODE:
      return {
        ...state,
        maintenanceMode: true,
        maintenanceData: action.payload
      }
    case SET_REFERER:
      return {
        ...state,
        referrer: action.payload.source,
        referrerData: action.payload.data
      }

    default:
      return state
  }
}

export const getTermsByCurrency = state => {
  const currency = get(state, 'activeSearchFilterData.currency', 'USD')
  const isBundlePricing = getIsBundlePricing(state)
  const labels = getLabelsData()
  const currencyTerms = get(labels, [`${currency.toLowerCase()}-terms`])
  const result = currencyTerms ? currencyTerms : get(labels, 'int-terms')

  if (currency !== 'USD' && result && result.terms_content) {
    return {
      ...result,
      terms_content: formatCode(result.terms_content, currency)
    }
  }

  if (isBundlePricing && result && result.terms_content) {
    return {
      ...result,
      terms_content: formatTerms(result.terms_content)
    }
  }

  return result
}
