import React from 'react'

const Price = ({ price, currencySymbol, currencySymbolPlacement }) => {
  if (currencySymbolPlacement === 'start') {
    return (
      <>
        {currencySymbol}
        {price}
      </>
    )
  }

  return (
    <>
      {price}
      {currencySymbol}
    </>
  )
}

export default Price
