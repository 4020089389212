export const nonStandardCurrencySymbols = {
  SGD: 'S$',
  HKD: 'HK$',
  EUR: '€'
}

export const currencyNames = {
  USD: 'US Dollars',
  CAD: 'Canadian Dollars',
  SGD: 'Singapore Dollars',
  HKD: 'Hong Kong Dollars'
}

export const currencyCode = {
  AST: 'AST',
  AUD: 'AUD',
  BEF: 'BEF',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  DEM: 'DEM',
  DKK: 'DKK',
  ESP: 'ESP',
  EUR: 'EUR',
  FRF: 'FRF',
  GBP: 'GPB',
  HKD: 'HKD',
  IEP: 'IEP',
  ITL: 'ITL',
  MXN: 'MXN',
  NLG: 'NLG',
  NOK: 'NOK',
  USD: 'USD'
}

export const currencySymbol = {
  // AUSTRIAN SCHILLING
  AST: {
    symbol: ' öS',
    placement: 'end'
  },
  // AUSTRALIAN DOLLAR
  AUD: {
    symbol: '$',
    placement: 'start'
  },
  // BELGIUM FRANCS
  BEF: {
    symbol: ' Fr',
    placement: 'end'
  },
  // BRAZILIAN REAL
  BRL: {
    symbol: '$',
    placement: 'start'
  },
  // CANADIAN DOLLARS
  CAD: {
    symbol: '$',
    placement: 'start'
  },
  // SWISS FRANCS
  CHF: {
    symbol: ' Fr.',
    placement: 'end'
  },
  // CHINESE YUAN
  CNY: {
    symbol: '¥',
    placement: 'start'
  },
  // DEUTSCH MARK
  DEM: {
    symbol: ' DM',
    placement: 'end'
  },
  // DANISH KRONER
  DKK: {
    symbol: ' kr.',
    placement: 'end'
  },
  // SPANISH PESETA
  ESP: {
    symbol: ' ₧',
    placement: 'end'
  },
  // EURO
  EUR: {
    symbol: '€',
    placement: 'start'
  },
  // FRENCH FRANCS
  FRF: {
    symbol: ' F',
    placement: 'end'
  },
  // POUNDS STERLING
  GBP: {
    symbol: '£',
    placement: 'start'
  },
  // HONG KONG DOLLARS
  HKD: {
    symbol: '$',
    placement: 'start'
  },
  // IRISH PUNTS
  IEP: {
    symbol: '£',
    placement: 'start'
  },
  // ITALIAN LIRAS
  ITL: {
    symbol: '₤',
    placement: 'start'
  },
  // MEXICAN PESO
  MXN: {
    symbol: '$',
    placement: 'start'
  },
  // DUTCH GUILDER
  NLG: {
    symbol: 'ƒ',
    placement: 'start'
  },
  // NORWEGIAN KRONOR
  NOK: {
    symbol: ' kr',
    placement: 'end'
  },
  // NEN ZEALAND DOLLAR
  NZD: {
    symbol: '$',
    placement: 'start'
  },
  // SWEDISH KRONOR
  SEK: {
    symbol: ' kr',
    placement: 'end'
  },
  // SINGAPORE DOLLAR
  SGD: {
    symbol: '$',
    placement: 'start'
  },
  // U. S. DOLLARS
  USD: {
    symbol: '$',
    placement: 'start'
  }
}

export const bundlePricingCountryCodes = ['USA']
