import { connect } from 'react-redux'
import { getIsBundlePricing } from '../reducers/fetchSsoReducers'

const TaxLine = ({
  isBundlePricing,
  currencySymbol,
  amount,
  currency,
  currencySymbolPlacement
}) => {
  const text = 'Taxes, fees & port expenses'

  if (currencySymbolPlacement === 'start') {
    return `${text} ${
      isBundlePricing ? 'included' : ''
    }: ${currencySymbol}${amount} ${currency}`
  }

  return `${text} ${
    isBundlePricing ? 'included' : ''
  }: ${amount} ${currencySymbol} ${currency}`
}

const mapStateToProps = state => {
  return {
    isBundlePricing: getIsBundlePricing(state)
  }
}

export default connect(mapStateToProps)(TaxLine)
