import { currencyCode } from '../configuration/currencies'

export const formatTerms = terms => {
  return terms.replace(/expenses are additional/gi, 'expenses are included')
}

export const formatCode = (terms, currency) => {
  const code =
    currencyCode[currency] !== undefined
      ? currencyCode[currency]
      : currencyCode.USD

  return terms.replace(/USD/gi, code)
}
