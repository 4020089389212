import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'
import PageHeader from '../../elements/PageHeader'
import {
  resetActiveCabins,
  resetActiveEmailConfiguration,
  resetActiveFilters,
  resetActivePackages
} from '../../../actions/resetQuoteFlow'
import setActiveStep, {
  setPreviousStep
} from '../../../actions/setActiveStepActions'
import updateSearchParams from '../../../utilities/updateSearchParams'
import { multipleCurrenciesAvailable } from '../../../reducers/fetchSsoReducers'
import {
  setActiveBrand,
  setActiveEndDate,
  setActiveRegion,
  setActiveStartDate,
  updateCurrency
} from '../../../actions/setActiveSearchFilters'
import {
  setActivePriceTableCurrencyFilter,
  setActivePriceTableDestinationFilter,
  setActivePriceTableCategoryFilter,
  setActivePriceTableBrandFilter
} from '../../../actions/setActivePriceTableSearchFilters'

import fetchDestinationMonths from '../../../actions/fetchDestinationMonths'
import EQuoteSearchFilterPriceSelect from './EQuoteSearchFilterPriceSelect'
import '../../../styles/scss/base/tables.scss'
import '../../../styles/scss/components/equote-sailings-full-search.scss'
import '../../../styles/scss/components/equote-destination-price-search.scss'
import fetchPackages from '../../../actions/fetchPackages'
import EQuoteSearchPriceTable from './EquoteSearchPriceTable'
import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'
import ArrowLeft from '../../../assets/icons/arrow-left.svg'
import ArrowRight from '../../../assets/icons/arrow-right.svg'
import syncHeaderBody from '../../../utilities/syncHeaderBody'
import { getBrandsData } from '../../../sessionStorage/getBrandsData'
import { getDestinationsData } from '../../../sessionStorage/getDestinationsData'
import { getLabelsData } from '../../../sessionStorage/getLabelsData'
import { currencyNames } from '../../../configuration/currencies'
import { formatTerms } from '../../../utilities/formatTerms'

const tableCellHeight = '87px'
const destinationCellWidth = '200px'
const headerHeight = '56px'

function toggleRightFade(tableScrollContainer) {
  const container = document.getElementById(
    'equote__sailings-search-price-table-container'
  )
  const rightArrow = document.querySelector(
    '#equote__sailings-search-price-table-container .scroll-right'
  )
  const leftArrow = document.querySelector(
    '#equote__sailings-search-price-table-container .scroll-left'
  )

  if (tableScrollContainer.scrollLeft <= 0) {
    leftArrow && leftArrow.classList.add('u-element-hidden')
  } else {
    leftArrow && leftArrow.classList.remove('u-element-hidden')
  }

  if (
    tableScrollContainer.scrollLeft + tableScrollContainer.clientWidth <
    tableScrollContainer.scrollWidth
  ) {
    container && container.classList.add('show-fade')
    rightArrow && rightArrow.classList.remove('u-element-hidden')
  } else {
    container && container.classList.remove('show-fade')
    rightArrow && rightArrow.classList.add('u-element-hidden')
  }
}

class EQuoteSearchPriceTableContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.wrapperRef = createRef()
    this.searchPriceTable = createRef()
  }

  scrollTable = direction => {
    const tableContainer = document.getElementById(
      'equote__search-price-table-container'
    )
    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]
    const table = tableScrollContainer.getElementsByTagName('table')[0]
    const offSet = tableScrollContainer.getBoundingClientRect().left
    const left = table.getBoundingClientRect().left
    const widthPerCell = table
      .querySelectorAll('.u-table-price-cell:not(.u-element-remove)')[0]
      .getBoundingClientRect().width
    const newLeft =
      direction === 'right'
        ? widthPerCell * 2 + offSet - left
        : offSet - left - widthPerCell * 2
    this.lastPositionLeft = newLeft
    tableScrollContainer.scrollTo({ left: newLeft, behavior: 'smooth' })
  }

  updateScrollPosition = e => {
    this.lastPositionLeft = e.currentTarget.scrollLeft
  }

  onScrollHorizontallyRepaintDestinationHeader = () => {
    if (this.searchPriceTable.current) {
      let destinationHeader = this.searchPriceTable.current.querySelector(
        '.equote__search-price-table-fixed-destination-header'
      )
      let table = this.searchPriceTable.current.querySelector(
        '#equote__search-price-table-container'
      )
      destinationHeader.style.left = table.getBoundingClientRect().left + 'px'
    }
  }

  onScrollRepaintHead = () => {
    let resultTable = this.searchPriceTable.current.querySelector('table')
    let headerTable = resultTable && resultTable.querySelector('thead')
    let mockHeader = this.searchPriceTable.current.querySelector(
      '.equote__search-price-table-mock-destination-header'
    )

    let newHeader = mockHeader.querySelector('thead')
    let table = this.searchPriceTable.current.querySelector(
      '#equote__search-price-table-container'
    )
    let body = this.searchPriceTable.current.querySelector(
      '#equote__search-price-table-container > div > div > div'
    )
    let destinationHeader = this.searchPriceTable.current.querySelector(
      '.equote__search-price-table-fixed-destination-header'
    )
    if (this.wrapperRef.current.offsetTop + 56 <= window.scrollY) {
      if (!newHeader) {
        destinationHeader.style.display = 'block'
        destinationHeader.style.position = 'fixed'
        destinationHeader.style.left = table.getBoundingClientRect().left + 'px'
        newHeader = headerTable.cloneNode(true)
        headerTable.style.visibility = 'collapse'
        newHeader.classList.add('u-position-sticky')
        mockHeader.classList.add('syncscroll')
        mockHeader.setAttribute('name', 'headersync')
        mockHeader.classList.toggle('u-position-sticky')
        mockHeader.prepend(newHeader)
        mockHeader.style.width = '100%'
        body.classList.add('syncscroll')
        body.setAttribute('name', 'headersync')
        syncHeaderBody()

        if (this.lastPositionLeft) {
          body.scrollTo({ left: this.lastPositionLeft })
          mockHeader.scrollTo({
            left: this.lastPositionLeft
          })
        }
      }
    } else {
      destinationHeader.style.display = 'none'
      newHeader && mockHeader.removeChild(newHeader)
      mockHeader.style.width = '100px'
      mockHeader.classList.remove('u-position-sticky')
      headerTable.style.visibility = 'visible'

      if (window.innerWidth < 480) {
        mockHeader.style.width = '120px'
      } else {
        mockHeader.style.width = '200px'
      }
    }

    if (
      this.wrapperRef.current.offsetTop +
        this.wrapperRef.current.clientHeight -
        56 <=
      window.scrollY
    ) {
      mockHeader.style.display = 'none'
    } else {
      mockHeader.style.display = 'block'
    }
  }

  componentDidMount() {
    triggerCustomGaEvent('equoteSearch-price', {
      ...this.props.activeSearchFilterData,
      ...this.props.loyaltyData
    })
    let body = document.querySelector(
      '#equote__search-price-table-container > div > div > div'
    )
    body.addEventListener('scroll', this.updateScrollPosition)
    window.addEventListener('scroll', this.onScrollRepaintHead)
    window.addEventListener(
      'resize',
      this.onScrollHorizontallyRepaintDestinationHeader
    )

    const tableContainer = this.searchPriceTable.current.querySelector(
      '#equote__search-price-table-container'
    )

    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]
    if (tableContainer && tableScrollContainer) {
      if (tableScrollContainer) {
        tableScrollContainer.addEventListener('scroll', () => {
          toggleRightFade(tableScrollContainer)
        })

        toggleRightFade(tableScrollContainer)
      } else {
        document
          .getElementById('equote__sailings-search-price-table-container')
          .classList.remove('show-fade')
      }
    }
  }

  componentDidUpdate() {
    const tableContainer = this.searchPriceTable.current.querySelector(
      '#equote__search-price-table-container'
    )

    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]

    window.addEventListener('scroll', this.onScrollRepaintHead)
    tableScrollContainer.addEventListener('scroll', () => {
      toggleRightFade(tableScrollContainer)
    })
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.onScrollHorizontallyRepaintDestinationHeader
    )
    let body = document.querySelector(
      '#equote__search-price-table-container > div > div > div'
    )
    window.removeEventListener('scroll', this.onScrollRepaintHead)
    body.removeEventListener('scroll', this.updateScrollPosition)

    const tableContainer = document.getElementById(
      'equote__search-price-table-container'
    )
    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]
    if (tableScrollContainer) {
      tableScrollContainer.removeEventListener('scroll', () =>
        toggleRightFade()
      )
    }
  }

  renderDisclaimer() {
    const { activeSearchByPriceTableFilters, brands } = this.props
    let disclaimer
    if (
      activeSearchByPriceTableFilters.brand === 'Z' ||
      activeSearchByPriceTableFilters.brand === 'R' ||
      activeSearchByPriceTableFilters.brand === 'C'
    ) {
      const currentBrand =
        brands &&
        brands.find(
          brand => brand.brand_code === activeSearchByPriceTableFilters.brand
        )
      disclaimer =
        currentBrand && currentBrand.disclaimer_pricing
          ? currentBrand.disclaimer_pricing
          : null
    } else {
      const currentBrand =
        brands && brands.find(brand => brand.brand_code === 'D')

      disclaimer =
        currentBrand && currentBrand.disclaimer_pricing
          ? currentBrand.disclaimer_pricing
          : null
    }

    if (activeSearchByPriceTableFilters.currency !== 'USD') {
      return disclaimer.replace(
        /USD/gi,
        activeSearchByPriceTableFilters.currency
      )
    }

    if (activeSearchByPriceTableFilters.currency === 'USD') {
      return formatTerms(disclaimer)
    }

    if (disclaimer.charAt(0) !== '*') {
      disclaimer = '* ' + disclaimer
    }

    return disclaimer
  }

  handlePriceSelection(pricingInfo) {
    const {
      activeSearchFilterData,
      handleActiveBrand,
      handleActiveDestination,
      handleActiveStartDate,
      handleActiveEndDate,
      handleSubmitQueryPackages,
      selectedBrand,
      selectedCurrency
    } = this.props

    const { destination, month } = pricingInfo

    const momentStartDate = moment(month, 'YYYY_MM')
    const startDate = momentStartDate.isSame(moment(), 'month')
      ? moment().format('YYYY-MM-DD')
      : momentStartDate
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD')

    const endDate = momentStartDate
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    const destinationQuery = {
      brand: selectedBrand,
      bookingType: activeSearchFilterData.bookingType,
      endDate,
      cruiseType: activeSearchFilterData.cruiseType,
      currency: selectedCurrency,
      startDate: startDate,
      region: destination,
      sso: activeSearchFilterData.sso
    }

    handleActiveBrand(selectedBrand)
    handleActiveDestination(destination)
    handleActiveStartDate(startDate)
    handleActiveEndDate(endDate)
    updateSearchParams(destinationQuery)
    handleSubmitQueryPackages()

    triggerCustomGaEvent('price-table-selection', { ...destinationQuery })
  }

  handleDestinationSelection(destination) {
    const {
      activeSearchFilterData,
      handleActiveBrand,
      handleActiveDestination,
      handleActiveStartDate,
      handleSubmitQueryPackages,
      selectedCurrency,
      selectedBrand
    } = this.props

    const startDate = moment().format('YYYY-MM-DD'),
      destinationQuery = {
        brand: selectedBrand,
        bookingType: activeSearchFilterData.bookingType,
        count: '150',
        cruiseType: activeSearchFilterData.cruiseType,
        currency: selectedCurrency,
        startDate: startDate,
        region: destination,
        sso: activeSearchFilterData.sso
      }
    handleActiveBrand(selectedBrand)
    handleActiveDestination(destination)
    handleActiveStartDate(startDate)
    updateSearchParams(destinationQuery)
    handleSubmitQueryPackages()
  }

  handleResetQuoteFlow = () => {
    triggerCustomGaEvent('equote-begin-new-search', {
      component: 'SearchPriceTableContainer'
    })
    const { activeSearchFilterData, resetQuoteFlow } = this.props

    let resetQuery = {
      sso: activeSearchFilterData.sso
    }

    resetQuoteFlow()
    updateSearchParams(resetQuery)
  }

  renderBrandSelect() {
    const { brands } = this.props

    if (!brands) return null
    const options = brands.map(brand => {
      if (brand.brand_code === 'D') return null
      return {
        key: brand.brand_code,
        value: brand.brand_code,
        label: brand.name
      }
    })

    return (
      <EQuoteSearchFilterPriceSelect
        value={this.props.selectedBrand}
        onChange={event => {
          triggerCustomGaEvent('price-table-filter', {
            filter: 'brand',
            value: event.target.value
          })
          this.props.setActivePriceTableBrandFilter(event.target.value)
        }}
        defaultValue="C,R"
        defaultLabel="All Brands"
        options={options}
      />
    )
  }

  renderDestinationSelect() {
    const { destinations } = this.props
    const options = destinations.map(destination => {
      return {
        key: destination.destination_code,
        value: destination.destination_code,
        label: destination.name
      }
    })
    return (
      <EQuoteSearchFilterPriceSelect
        value={this.props.selectedDestination}
        onChange={event => {
          triggerCustomGaEvent('price-table-filter', {
            filter: 'destination',
            value: event.target.value
          })
          this.props.setActivePriceTableDestinationFilter(event.target.value)
        }}
        defaultValue="all"
        defaultLabel="Destinations"
        options={options}
      />
    )
  }

  renderCategorySelect() {
    return (
      <EQuoteSearchFilterPriceSelect
        value={this.props.selectedCategory}
        onChange={event => {
          triggerCustomGaEvent('price-table-filter', {
            filter: 'category',
            value: event.target.value
          })
          this.props.setActivePriceTableCategoryFilter(event.target.value)
        }}
        defaultValue="bestUnfilteredRate"
        defaultLabel="Category"
        options={[
          {
            value: 'I',
            label: 'Interior'
          },
          {
            value: 'O',
            label: 'Oceanview'
          },
          {
            value: 'B',
            label: 'Balcony'
          },
          {
            value: 'D',
            label: 'Deluxe'
          }
        ]}
      />
    )
  }

  renderCurrencySelect() {
    if (!this.props.multipleCurrenciesAvailable) return null

    const { transactionData } = this.props
    const options = transactionData.paymentLevels.map(paymentOption => {
      return {
        key: paymentOption.paymentCurrencyCode,
        value: paymentOption.paymentCurrencyCode,
        label: paymentOption.paymentCurrencyCode
      }
    })
    return (
      <div className="equote__search-price-table--filters-row">
        <div className="equote__search-price-table--filters-row-group">
          <h4>Currency</h4>

          <EQuoteSearchFilterPriceSelect
            value={this.props.selectedCurrency}
            onChange={event => {
              triggerCustomGaEvent('price-table-filter', {
                filter: 'currency',
                value: event.target.value
              })
              this.props.updateCurrency(event.target.value)
            }}
            defaultValue="all"
            defaultLabel="Destinations"
            options={options}
          />
        </div>
      </div>
    )
  }

  renderMonthCell(pricingInfo) {
    const guestCount =
      this.props.activeSearchFilterData &&
      this.props.activeSearchFilterData.guestCount
        ? Number(this.props.activeSearchFilterData.guestCount)
        : 2

    if (!pricingInfo) {
      return (
        <div className="u-table-price-cell u-element-remove">
          <button className="price-info-button u-transparent">
            <span className="price-info-button--best-price">
              $
              <span className="price-info-button--best-price-value">
                {(0).toFixed(2)}
              </span>
            </span>
            <span className="equote__sailings-table-best-price-description u-font-uppercase" />
          </button>
        </div>
      )
    }
    return (
      <div
        onClick={() => this.handlePriceSelection(pricingInfo)}
        className="u-table-price-cell"
      >
        <button className="price-info-button u-transparent">
          <span className="price-info-button--best-price">
            $
            <span className="price-info-button--best-price-value">
              {(pricingInfo.netPrice / guestCount).toFixed(2)}
            </span>
          </span>
          <span className="equote__sailings-table-best-price-description u-font-uppercase">
            {pricingInfo.leadPromoDesc}
          </span>
        </button>
      </div>
    )
  }

  renderDestinationCell(destination) {
    return (
      <div className={`destination-cell`}>
        <button
          onClick={
            destination &&
            destination.destinationInfo &&
            destination.destinationInfo.destination_code
              ? () =>
                  this.handleDestinationSelection(
                    destination.destinationInfo.destination_code
                  )
              : null
          }
          className="destination-info-button u-transparent"
        >
          <span>
            {destination.destinationInfo && destination.destinationInfo.name
              ? destination.destinationInfo.name
              : null}
          </span>
        </button>
      </div>
    )
  }

  renderColumns() {
    const columns = [
      {
        title: 'Destination',
        field: 'destination',
        customSort: (a, b) => {
          const first =
            a &&
            a.destination &&
            a.destination.destinationInfo &&
            a.destination.destinationInfo.name
              ? a.destination.destinationInfo.name
              : 'a'
          const second =
            b &&
            b.destination &&
            b.destination.destinationInfo &&
            b.destination.destinationInfo.name
              ? b.destination.destinationInfo.name
              : 'a'
          return first < second ? -1 : 1
        },
        sorting: true,
        // defaultFilter: this.props.filterTerms.brand,
        render: rowData => {
          return rowData.destination
            ? this.renderDestinationCell(rowData.destination)
            : null
          // <span>
          //   {rowData.destination.destinationInfo
          //     ? rowData.destination.destinationInfo.name
          //     : null}
          // </span>
        },
        cellStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 'auto',
          minWidth: 200,
          maxWidth: 200,
          height: tableCellHeight,
          zIndex: 2,
          position: 'absolute'
        },
        headerStyle: {
          minWidth: 200,
          maxWidth: 200,
          height: headerHeight
        }
      }
    ]

    if (this.props.availableMonths && this.props.availableMonths.length) {
      for (const month of this.props.availableMonths) {
        columns.push({
          title: moment(month, 'YYYY-MM').format('MMM | YYYY'),
          defaultSort: 'desc',
          customSort: (a, b) => {
            const aNetPrice =
              a[month] && a[month].netPrice ? a[month].netPrice : null
            const bNetPrice =
              b[month] && b[month].netPrice ? b[month].netPrice : null
            if (!aNetPrice && !bNetPrice) return 0
            if (aNetPrice && !bNetPrice) return -1
            if (bNetPrice && !aNetPrice) return 1

            return aNetPrice - bNetPrice
          },
          field: month,
          render: rowData => {
            if (!rowData[month]) return null
            return rowData[month] ? this.renderMonthCell(rowData[month]) : null
          },
          cellStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            margin: 'auto',
            minWidth: 100,
            maxWidth: 100,
            height: tableCellHeight,
            zIndex: 2,
            textAlign: 'center'
          },
          headerStyle: {
            // minWidth: 200,
            // maxWidth: 200,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            minWidth: 100,
            maxWidth: 100,
            height: headerHeight,
            textAlign: 'center'
          }
        })
      }
    }
    return columns
  }

  renderData() {
    const {
      selectedBrand,
      selectedDestination,
      selectedCategory,
      destinationMonthsData,
      destinations
    } = this.props
    const data = []
    const datesAvailable = {}
    if (destinationMonthsData && Object.keys(destinationMonthsData).length) {
      for (const [destinationKey, months] of Object.entries(
        destinationMonthsData
      )) {
        const destinationData = {}
        if (
          selectedDestination !== 'all' &&
          destinationKey !== selectedDestination
        ) {
          continue
        }

        for (const [monthKey, month] of Object.entries(months)) {
          let pricing = null
          if (selectedBrand !== 'C,R') {
            if (month[selectedBrand]) {
              Object.entries(month[selectedBrand]).forEach(stateroom => {
                if (
                  selectedCategory !== 'bestUnfilteredRate' &&
                  stateroom[0] !== selectedCategory
                ) {
                  return
                }
                if (!pricing || pricing.netPrice > stateroom.netPrice) {
                  pricing = {
                    ...stateroom[1],
                    stateroomType: stateroom[0],
                    brand: selectedBrand,
                    month: monthKey,
                    destination: destinationKey
                  }
                }
              })
            }
          } else {
            pricing =
              selectedCategory === 'bestUnfilteredRate'
                ? month.bestUnfilteredRate
                : month.bestRateByCategory[selectedCategory]
          }

          destinationData[monthKey] = pricing
        }

        const destinationInfo = destinations.find(
          destination => destination.destination_code === destinationKey
        )

        data.push({
          destination: destinationInfo
            ? { destinationInfo, destinationKey }
            : {
                destinationInfo: {
                  name: destinationKey,
                  destination_code: destinationKey
                },
                destinationKey
              },
          ...destinationData
        })
      }
    }

    return data.map(date => {
      return { ...datesAvailable, ...date }
    })
  }

  render() {
    const { labelsData } = this.props
    const tableColumns = this.renderColumns()
    const data = this.renderData()
    const currencyCode = get(this, 'props.activeSearchFilterData.currency')
    const currencyName = currencyNames[currencyCode]
    return (
      <div className="equote__search-price-table">
        <div className="u-content-container">
          <PageHeader
            title="Search and Compare Cruises by Price"
            subTitle={`Prices shown are lowest available for the criteria selected. Prices are in ${currencyName ||
              this.props.currency}.`}
            buttonText="BEGIN A NEW SEARCH"
            clickHandler={this.handleResetQuoteFlow}
          />
        </div>
        <div className="equote__search-price-table--filters">
          <div className="u-content-container">
            <div className="equote__search-price-table--filters-row">
              <h4>show prices based on</h4>
              <div className={'u-flex'}>
                {this.renderBrandSelect()}
                {this.renderCategorySelect()}
                {this.renderDestinationSelect()}
              </div>
            </div>
            {this.renderCurrencySelect()}
          </div>
        </div>

        <div
          ref={this.wrapperRef}
          className="equote__sailings-search-price-table-wrapper"
        >
          <div
            id="equote__sailings-search-price-table-container"
            className={`u-content-container show-fade`}
          >
            <button
              className="btn scroll-left"
              onClick={() => this.scrollTable('left')}
            >
              <img src={ArrowLeft} alt="scroll left" />
            </button>

            <button
              className="btn scroll-right"
              onClick={() => this.scrollTable('right')}
            >
              <img src={ArrowRight} alt="scroll right" />
            </button>
            <EQuoteSearchPriceTable
              ref={this.searchPriceTable}
              tableColumns={tableColumns}
              headerHeight={headerHeight}
              destinationCellWidth={destinationCellWidth}
              data={data}
            />
          </div>
        </div>
        {labelsData && labelsData.email && labelsData.email.configure_terms && (
          <div className="equote__search-price-table-disclaimer">
            <h5>Terms and Conditions</h5>
            <p className="u-remove-margin u-txt-italic u-font-small">
              {this.renderDisclaimer()}
            </p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeSearchFilterData: state.activeSearchFilterData,
    currency: state.activeSearchFilterData
      ? state.activeSearchFilterData.currency === 'USD'
        ? 'US'
        : 'Canadian'
      : 'US',
    brands: getBrandsData(),
    destinations: getDestinationsData(),
    multipleCurrenciesAvailable: multipleCurrenciesAvailable(state),
    transactionData: state.ssoData.transactionData,
    labelsData: getLabelsData(),
    selectedCurrency: state.activeSearchByPriceTableFilters.currency,
    selectedBrand: state.activeSearchByPriceTableFilters.brand,
    selectedDestination: state.activeSearchByPriceTableFilters.destination,
    selectedCategory: state.activeSearchByPriceTableFilters.category,
    activeSearchByPriceTableFilters: state.activeSearchByPriceTableFilters,
    loyaltyData: state.loyaltyData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetQuoteFlow() {
      return (
        dispatch(resetActiveCabins()),
        dispatch(resetActivePackages()),
        dispatch(resetActiveFilters()),
        dispatch(resetActiveEmailConfiguration()),
        dispatch(setActiveStep(0)),
        dispatch(setPreviousStep(null))
      )
    },
    updateCurrency(currency) {
      dispatch(updateCurrency(currency))
      dispatch(setActivePriceTableCurrencyFilter(currency))
      dispatch(fetchDestinationMonths())
    },
    handleActiveBrand(brand) {
      return dispatch(setActiveBrand(brand))
    },
    handleActiveDestination(destination) {
      return dispatch(setActiveRegion(destination))
    },
    handleActiveStartDate(startDate) {
      return dispatch(setActiveStartDate(startDate))
    },
    handleActiveEndDate(endDate) {
      return dispatch(setActiveEndDate(endDate))
    },
    handleSubmitQueryPackages() {
      dispatch(setActiveStep(1))
      dispatch(setPreviousStep(5))
      return dispatch(fetchPackages())
    },
    setActivePriceTableDestinationFilter(destination) {
      dispatch(setActivePriceTableDestinationFilter(destination))
    },
    setActivePriceTableCategoryFilter(category) {
      dispatch(setActivePriceTableCategoryFilter(category))
    },
    setActivePriceTableBrandFilter(brand) {
      dispatch(setActivePriceTableBrandFilter(brand))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchPriceTableContainer)
