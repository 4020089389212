import React, { Fragment } from 'react'
import EQuoteCabinsTableButton from '../components/cabins/EQuoteCabinsTableButton'
import formatPrice from './formatPrice'
import Price from '../components/Price'

const renderRowData = ({
  rowData,
  isSelectableTable,
  packageData,
  currencySymbol,
  currencySymbolPlacement
}) => {
  return (
    rowData && (
      <Fragment>
        {rowData.displayName && <span>{rowData.displayName}</span>}
        {isSelectableTable &&
          rowData.displayPrice &&
          (rowData.isSelectable ? (
            <EQuoteCabinsTableButton
              cabinData={rowData}
              packageData={packageData}
              label={
                rowData.isRefundable
                  ? 'Regular Deposit'
                  : 'Non-Refundable Deposit'
              }
            />
          ) : (
            <div style={{ minHeight: '16px' }} />
          ))}
        {rowData.displayPrice && (
          <>
            <span className="equote__sailings-table-best-price">
              <Price
                price={Number(rowData.displayPrice).toFixed(2)}
                currencySymbol={currencySymbol}
                currencySymbolPlacement={currencySymbolPlacement}
              />
            </span>
            <br />
            {Boolean(rowData.displayCreditAmt) && (
              <>
                <span className="equote__sailings-table-best-price-description">
                  {currencySymbol}
                  {formatPrice(rowData.displayCreditAmt)} OBC
                </span>
                <br />
              </>
            )}
            <span className="equote__sailings-table-best-price-description">
              {rowData.displayLeadPromoDesc}
            </span>
          </>
        )}
      </Fragment>
    )
  )
}

export default function({
  columns = [],
  cabins = {},
  headerStyle = {},
  isSelectableTable = false,
  packageData,
  currencySymbol,
  currencySymbolPlacement
}) {
  const initialColumnsCount = columns.length

  // The reducer is only used to ensure we only push the columns we need.
  // We may have more cabins pricing than columns need.
  // We push to and return columns
  Object.values(cabins).reduce((obj, cabin) => {
    // This allows us to push the title to the correct column
    let lowestCabinOrderNumber

    Object.values(cabin).forEach(val => {
      if (
        val &&
        val.order &&
        (!lowestCabinOrderNumber || val.order < lowestCabinOrderNumber)
      ) {
        lowestCabinOrderNumber = val.order
      }
    })
    Object.entries(cabin).forEach(([priceGroupKey, priceGroup]) => {
      // we only care about the priceGroups with pricing info
      if (priceGroup && priceGroup.netPrice) {
        let title
        if (isSelectableTable) {
          title = priceGroup.pricingDisplayName
        } else {
          /*
           * This is different than what is currently showing in the mocks.
           * This is correct.
           * https://jira.verbinteractive.com/browse/RCL102-61
           * */
          title = priceGroup.isRefundable
            ? 'Best Rate, Regular Deposit'
            : 'Best Rate, Non-Refundable Deposit*'
        }

        /* further down we sort our columns, this ensures we get
         * non-refundable first
         */
        const order = priceGroup.isRefundable
          ? priceGroup.order * 2 + initialColumnsCount + 1
          : priceGroup.order + initialColumnsCount

        const key = priceGroup.isRefundable ? 'refundable' : 'nonRefundable'

        // first refundable or nonRefundable category
        // we only want a title on one refundable and one nonRefundable column
        // and we need it to be on the first column
        if (!obj[key] && priceGroup.order === lowestCabinOrderNumber) {
          columns.push({
            title,
            field: priceGroupKey,
            order,
            headerStyle: {
              ...headerStyle,
              borderLeft: '1px solid #fff'
            },
            render: rowData => {
              const data =
                rowData && rowData[priceGroupKey] ? rowData[priceGroupKey] : {}

              return renderRowData({
                rowData: {
                  ...data,
                  isSelectable: isSelectableTable
                },
                isSelectableTable,
                packageData,
                currencySymbol,
                currencySymbolPlacement
              })
            }
          })

          obj[key] = {}
          obj[priceGroupKey] = {}
        }

        // we only want one column for each priceGroupKey.
        // ie: refundable-1st/2nd
        if (!obj[priceGroupKey]) {
          columns.push({
            title: isSelectableTable ? priceGroup.pricingDisplayName : '',
            field: priceGroupKey,
            order,
            headerStyle: {
              ...headerStyle,
              backgroundColor: 'transparent !important',
              borderLeft: isSelectableTable ? '1px solid #fff' : ''
            },
            render: rowData => {
              return renderRowData({
                rowData:
                  rowData && rowData[priceGroupKey]
                    ? rowData[priceGroupKey]
                    : {},
                isSelectableTable,
                packageData,
                currencySymbol,
                currencySymbolPlacement
              })
            }
          })
          obj[priceGroupKey] = {}
        }
      }
    })
    return obj
  }, {})
  return columns.sort((a, b) => (a.order < b.order ? -1 : 1))
}
