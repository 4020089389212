import React from 'react'
import { get } from 'lodash'

import EQuoteCabinsTable from './EQuoteCabinsTable'
import EQuoteCabinsTableSuperCatCheckBox from './EQuoteCabinsTableSuperCatCheckBox'
import formatPrice, { formatDisplayPrice } from '../../utilities/formatPrice'
import TaxLine from '../TaxLine'
import Price from '../Price'

const EQuoteCabinsTableAccordion = props => {
  const {
    activePackage,
    currentCategoryData,
    isEditOpen,
    handleToggleEdit
  } = props
  const currencySymbol = get(activePackage, 'currencySymbol', '')
  const currencySymbolPlacement = get(
    activePackage,
    'currencySymbolPlacement',
    ''
  )
  const bestRate =
    currentCategoryData &&
    currentCategoryData.superCategoryData &&
    currentCategoryData.superCategoryData.bestGuestCountRate
      ? formatPrice(
          currentCategoryData.superCategoryData.bestGuestCountRate
            .perPersonAveragePrice
        )
      : null

  let stateRoomDescription = null
  if (
    currentCategoryData.superCategoryData.stateroomDescription === 'Outside'
  ) {
    stateRoomDescription = 'Ocean View'
  } else {
    stateRoomDescription = currentCategoryData.superCategoryData.categoryName
      ? currentCategoryData.superCategoryData.categoryName
      : currentCategoryData.superCategoryData.stateroomDescription
  }

  const buttonTitle =
    stateRoomDescription === 'Suites' ? 'Suite' : stateRoomDescription
  const taxes = get(
    currentCategoryData,
    'superCategoryData.bestGuestCountRate.priceInfo.taxesFees'
  )

  return (
    <div className="equote__cabins-table-accordion">
      <div className="equote__cabins-table-heading">
        <div className="equote__cabins-table-toggle">
          <EQuoteCabinsTableSuperCatCheckBox
            activePackage={activePackage}
            currentCategoryData={currentCategoryData}
          />
        </div>

        <div className="equote__cabins-table-details">
          {currentCategoryData.superCategoryData.stateroomDescription ? (
            <p className="title u-remove-margin">{stateRoomDescription}</p>
          ) : null}

          {bestRate ? (
            <p className="price u-remove-margin">
              {'From '}
              <Price
                price={Number(bestRate).toFixed(2)}
                currencySymbol={currencySymbol}
                currencySymbolPlacement={currencySymbolPlacement}
              />
            </p>
          ) : null}
          {taxes && (
            <p className="taxes u-remove-margin">
              <i>
                <TaxLine
                  currencySymbol={currencySymbol}
                  currencySymbolPlacement={currencySymbolPlacement}
                  amount={formatDisplayPrice(taxes)}
                  currency=""
                />
              </i>
            </p>
          )}
        </div>

        <div className="equote__cabins-table-edit">
          <button className="btn btn-white" onClick={() => handleToggleEdit()}>
            {isEditOpen
              ? `Close ${buttonTitle} Categories`
              : `Select ${buttonTitle} Categories`}
          </button>
        </div>
      </div>

      {isEditOpen && (
        <>
          {currentCategoryData && currentCategoryData.pricing ? (
            <EQuoteCabinsTable
              isEditOpen={isEditOpen}
              handleToggleEdit={handleToggleEdit}
              activePackage={activePackage}
              availableCabins={currentCategoryData.pricing}
            />
          ) : (
            'Error'
          )}
        </>
      )}
    </div>
  )
}

export default EQuoteCabinsTableAccordion
