import moment from 'moment'
import { get } from 'lodash'
import formatStateRoom from './formatStateRoom'
import formatImages from './formatImages'
import formatLeadPromoDesc from './formatLeadPromoDesc'
// see comment below
// import { allowedLandPackagesBrands } from '../configuration/brands'
import formatItinerary from './formatItinerary'

// This formats our response for redux
export default function(
  cruises,
  guestCount,
  currency,
  currencySymbol,
  currencySymbolPlacement
) {
  return cruises.map(cruise => {
    const isSoldOut =
      !cruise.hasUnsupportedQualifiers &&
      (!cruise.pricing ||
        !cruise.pricing.pricing ||
        !Object.values(cruise.pricing.pricing).some(
          price =>
            price &&
            price.some(stateRoom => {
              return (
                stateRoom && stateRoom.bestRate && stateRoom.bestRate.netPrice
              )
            })
        ))

    const pricing = cruise.pricing
      ? formatGuestCountPricing(cruise.pricing, guestCount, cruise.brand)
      : null

    const relatedSailings = cruise.relatedSailings
      ? cruise.relatedSailings.reduce((arr, sailing) => {
          if (moment(sailing.sailDate, 'YYYY-MM-DD').isAfter(moment(), 'day')) {
            arr.push(sailing)
          }
          return arr
        }, [])
      : []

    /*
     * With the removal of Azamara this is no longer needed.
     * Leaving as a comment in case it gets added by other brands, or Aza returns.
     * */

    // const hasLandPackages = Boolean(
    //   landPackages &&
    //     Object.keys(landPackages).length &&
    //     allowedLandPackagesBrands.some(
    //       allowedBrand => allowedBrand === cruise.brand
    //     ) &&
    //     cruise.hasOwnProperty('prePost') &&
    //     cruise.prePost.landPackages &&
    //     cruise.prePost.landPackages.length
    // )

    const itinerary = formatItinerary(cruise.itinerary)

    let totalNights = cruise.sailingNights,
      tourTypeLabel
    // justACruise true is for cruises and false for cruise tours.
    if (cruise.hasOwnProperty('justACruise') && !cruise.justACruise) {
      totalNights = totalNights + cruise.preTourNights + cruise.postTourNights
      // can't use the label 'PRE/POST' or the table won't sort it correctly
      // need to set it to both here and render at PRE/POST in the tables
      // and filters
      tourTypeLabel =
        cruise.preTourNights && cruise.postTourNights
          ? 'BOTH'
          : cruise.preTourNights
          ? 'PRE'
          : 'POST'
    }

    return {
      ...cruise,
      isSoldOut,
      pricing,
      itinerary,
      relatedSailings,
      // hasLandPackages,
      // landPackages: hasLandPackages ? cruise.prePost.landPackages : null,
      landPackages: null,
      totalNights,
      tourTypeLabel,
      currency,
      currencySymbol,
      currencySymbolPlacement
    }
  })
}

export function formatGuestCountPricing(pricingData, guestCount, brand) {
  const { pricing, categories, staterooms } = pricingData

  /*
   * The business rules say that Azamara can never have 4th pricing.
   * They would still like to show results when searching for 4 guests but
   * not display the 4th column or show sold out, therefore, for Azamara only
   * we will override the results.
   * */
  let pricingKey = guestCount
  if (Number(guestCount) === 4 && brand === 'Z') {
    pricingKey = 3
  }

  const currentPrice = pricing ? pricing[pricingKey] : null

  if (!currentPrice || !currentPrice.length) {
    return { isSoldOut: true }
  }
  /*
   * The result will be an object keyed by super category codes I,O,B,D
   * Each contains an array of availableStateRooms,
   * bestGuestCountRate which is the stateroom with the lowest price
   * plus info about the stateroom.
   * */
  return currentPrice.reduce((obj, superCategory) => {
    if (!obj[superCategory.stateroomType]) {
      const matchingCategory =
        (categories && categories[superCategory.category]) || {}
      const category = {
        ...matchingCategory,
        image: formatImages(matchingCategory.image)
      }

      const formattedBestRate = formatStateRoomDetails(
        superCategory.bestRate,
        staterooms,
        pricingKey,
        brand
      )

      obj[superCategory.stateroomType] = {
        category,
        categoryName: matchingCategory.name,
        stateroomType: superCategory.stateroomType,
        stateroomDescription: superCategory.stateroomDescription,
        bestGuestCountRate: formattedBestRate ? formattedBestRate : null,
        availableStateRooms: []
      }
    }
    const { refundableCategories, nonRefundableCategories } = superCategory
    for (const stateRoom of [
      ...refundableCategories,
      ...nonRefundableCategories
    ]) {
      const formattedStateRoom = formatStateRoomDetails(
        stateRoom,
        staterooms,
        pricingKey,
        brand
      )
      if (formattedStateRoom) {
        obj[superCategory.stateroomType].availableStateRooms.push(
          formattedStateRoom
        )
        // this is just an additional check in case we din't get our bestRate
        if (
          !obj[superCategory.stateroomType].bestGuestCountRate ||
          obj[superCategory.stateroomType].bestGuestCountRate.netPrice >
            stateRoom.netPrice
        ) {
          obj[
            superCategory.stateroomType
          ].bestGuestCountRate = formattedStateRoom
        }
      }
    }
    return obj
  }, {})
}

function formatPricingData(guestPrices, brand, guestCount, stateRoom) {
  const pricingData = {}
  const { netPrice, creditAmt, leadPromoDesc, promos, priceInfo } = stateRoom
  // if the guestCount is 1 or 2 we are just going to use the stateRoom info
  if (guestCount <= 2) {
    pricingData[guestCount] = {
      netPrice,
      displayPrice: netPrice / guestCount,
      creditAmt,
      displayCreditAmt: creditAmt / guestCount,
      perPersonCreditAmt: creditAmt / guestCount,
      displayLeadPromoDesc: formatLeadPromoDesc(leadPromoDesc, promos),
      displayName: Number(guestCount) === 1 ? 'Single' : '1st/2nd', // used in our tables
      priceInfo
    }
    return pricingData
  }
  let passengerIndex = 1
  let singlePrice
  for (const priceGroup of guestPrices) {
    // these are the pre passenger breakdowns for pricing.
    if (
      priceGroup &&
      priceGroup.priceInfos &&
      priceGroup.priceInfos.priceInfo
    ) {
      const price = priceGroup.priceInfos.priceInfo.find(
        price => price.priceTypeCode === 'DISCOUNTED_CRUISE_FARE'
      )
      // if passengerIndex = 2 we need to get passenger one's price add them both and divide by two for our displayPrice
      // this is because guest one and two can have different prices but we always display them together.
      if (passengerIndex === 1 && price) {
        singlePrice = price.amount // save this for next loop
      }
      if (passengerIndex === 2) {
        const totalPrice = price.amount + singlePrice
        pricingData[2] = {
          netPrice: totalPrice,
          displayPrice: totalPrice / 2,
          displayName: '1st/2nd',
          creditAmt,
          displayCreditAmt: creditAmt / guestCount,
          displayLeadPromoDesc: formatLeadPromoDesc(leadPromoDesc, promos),
          priceInfo
        }
      }

      if (passengerIndex > 2) {
        // check if we need third and fourth pricing
        // if guestCount = 4,the passengerKey = 3 and the brand isn't C we need to check if the 3rd and 4th pricing is the same and skip the 3rd
        if (
          Number(guestCount) === 4 &&
          passengerIndex === 4 &&
          brand !== 'C' &&
          pricingData[3] &&
          pricingData[3].displayPrice === price.amount
        ) {
          delete pricingData[3]
        }
        // displayName is 3rd if only searching 3 guests.
        // 4th if searching 4 guests and the 3rd and 4th prices are different or brand is C
        // and 3rd/4th if searching for 4 guests and the price for 3 is the same.
        pricingData[passengerIndex] = {
          netPrice: price.amount,
          displayPrice: price.amount,
          displayCreditAmt: creditAmt / guestCount,
          displayLeadPromoDesc: formatLeadPromoDesc(leadPromoDesc, promos),
          displayName:
            passengerIndex === 3 ? '3rd' : pricingData[3] ? '4th' : '3rd/4th',
          priceInfo
        }
      }
      // we need a perPersonCreditAmt for tables rows
      if (passengerIndex === Number(guestCount)) {
        pricingData[passengerIndex] = {
          ...pricingData[passengerIndex],
          perPersonCreditAmt: creditAmt / guestCount
        }
      }
      passengerIndex += 1
    }
  }
  return pricingData
}

function formatStateRoomDetails(stateRoom, staterooms, guestCount, brand) {
  if (
    !stateRoom ||
    !stateRoom.guestPrices ||
    !stateRoom.guestPrices.guestPrice ||
    !stateRoom.guestPrices.guestPrice.length ||
    stateRoom.guestPrices.guestPrice.length !== Number(guestCount)
  ) {
    return null
  }

  const additionalPricing = formatPricingData(
    stateRoom.guestPrices.guestPrice,
    brand,
    guestCount,
    stateRoom
  )

  const matchingStateRoom = staterooms && staterooms[stateRoom.stateroom]
  const name = get(stateRoom, 'stateroomSubttype.description', null)

  // The API returns empty arrays instead of objects here.
  const matchingStateRoomIsArray = Array.isArray(matchingStateRoom)
  const formattedMatchingStateRoom =
    !matchingStateRoom || matchingStateRoomIsArray
      ? {
          name
        }
      : matchingStateRoom
  // if we got a matching stateroom but are missing the name.
  if (!formattedMatchingStateRoom.name) {
    formattedMatchingStateRoom.name = name
  }
  return formatStateRoom({
    ...stateRoom,
    stateroom: formattedMatchingStateRoom,
    isRefundable: stateRoom.refundability === 'REFUNDABLE',
    perPersonAveragePrice: stateRoom.netPrice / Number(guestCount),
    perPersonCreditAmt: Number(stateRoom.creditAmt) / guestCount,
    additionalPricing
  })
}
